.FieldDataSourceEditor {
	display: block;

	.btn-edit {
		margin-left: 8px;
		padding: 2px;
		padding: 2px;
	}

	.or-btn {
		padding-left: 8px;
	}
}
