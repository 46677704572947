.FieldPropertyEditor {
	//padding-top: 12px;
	padding-left: 8px;
	padding-right: 8px;
	width: 100%;
	border-left: rgba(0, 0, 0, 0.12) 1px solid;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	height: 100%;

	.heading-box {
		min-height: 46px;
	}

	.date-radio-group {
		//background-color: 
		//border: solid 1px #e9ecef;
		//box-shadow: 1px 1px 3px #9e9e9e inset;
		padding-left: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.answer-block {
		min-height: 90px;
		display: inline-flex;
		align-items: center;
		padding: 2px;
		max-height: 90px;
	}

	.heading-props {
		color: black;
		padding-left: 12px;
		font-size: 20px;
	}

	.btn-delete {
		color: #455060;
	}

	.MuiCheckbox-colorSecondary.Mui-disabled {
		color: rgba(0, 0, 0, 0.26);
	}

	.RepeatTimesSelector {
		//background-color: red;
		padding-top: 12px;
		margin-right: 10px;
		margin-left: 8px;
		padding-bottom: 12px;
	}

	.data-source-divider {
		margin-top: 0px;
		margin-left: 8px;
	}

	.FieldDataSourceEditor {
		margin-right: 10px;
		margin-left: 8px;
	}

	.options-from {
		
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: row;
		min-height: 38px;
	}

	.repeater-options {
		padding: 2px;

		.data-source-editor-row {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: row;
			min-height: 38px;
		}
	}

	.options {
		padding-top: 6px;
	}

	.prop-window {
		width: 100%;
		flex: 1;
	}

	.MuiTabs-indicator {
		//background-color: red;
		top: 0;
		bottom: unset;
	}
}

@import './components/OptionEditor/style.scss';
@import './components/RepeatTimesSelector/style.scss';
@import './components/FieldDataSourceEditor/style.scss';
@import './components/DataSourceRulesEditor/style.scss';
